var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-three-fifths"},[_c('DefaultForm',{attrs:{"is-saving":_vm.getTentIsSaving,"mode":_vm.mode},on:{"submit":_vm.onSubmit,"abort":_vm.onAbort},scopedSlots:_vm._u([{key:"default",fn:function(formSettings){return [_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$tc('modules.tent.tent', 1)),"type":_vm.getTentValidationErrors && _vm.getTentValidationErrors.tent
                  ? 'is-danger'
                  : '',"message":_vm.getTentValidationErrors && _vm.getTentValidationErrors.tent
                  ? _vm.getTentValidationErrors.tent
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"","placeholder":"Plant name","required":""},model:{value:(_vm.tent),callback:function ($$v) {_vm.tent=$$v},expression:"tent"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$tc('modules.tent.height_cm', 1)),"type":_vm.getTentValidationErrors && _vm.getTentValidationErrors.height_cm
                  ? 'is-danger'
                  : '',"message":_vm.getTentValidationErrors && _vm.getTentValidationErrors.height_cm
                  ? _vm.getTentValidationErrors.height_cm
                  : ''}},[_c('b-input',{attrs:{"type":"number","icon":"","placeholder":"Height in cm","required":""},model:{value:(_vm.heightCm),callback:function ($$v) {_vm.heightCm=$$v},expression:"heightCm"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$tc('modules.tent.width_cm', 1)),"type":_vm.getTentValidationErrors && _vm.getTentValidationErrors.width_cm
                  ? 'is-danger'
                  : '',"message":_vm.getTentValidationErrors && _vm.getTentValidationErrors.width_cm
                  ? _vm.getTentValidationErrors.width_cm
                  : ''}},[_c('b-input',{attrs:{"type":"number","icon":"","placeholder":"Width in cm","required":""},model:{value:(_vm.widthCm),callback:function ($$v) {_vm.widthCm=$$v},expression:"widthCm"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$tc('modules.tent.length_cm', 1)),"type":_vm.getTentValidationErrors && _vm.getTentValidationErrors.length_cm
                  ? 'is-danger'
                  : '',"message":_vm.getTentValidationErrors && _vm.getTentValidationErrors.length_cm
                  ? _vm.getTentValidationErrors.length_cm
                  : ''}},[_c('b-input',{attrs:{"type":"number","icon":"","placeholder":"Length in cm","required":""},model:{value:(_vm.lengthCm),callback:function ($$v) {_vm.lengthCm=$$v},expression:"lengthCm"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$tc('modules.tent.slot_count', 1)),"type":_vm.getTentValidationErrors && _vm.getTentValidationErrors.slot_count
                  ? 'is-danger'
                  : '',"message":_vm.getTentValidationErrors && _vm.getTentValidationErrors.slot_count
                  ? _vm.getTentValidationErrors.slot_count
                  : ''}},[_c('b-slider',{attrs:{"min":0,"max":20,"step":1,"tooltip":false,"indicator":""},model:{value:(_vm.slotCount),callback:function ($$v) {_vm.slotCount=$$v},expression:"slotCount"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$tc('modules.tent.light_on_time', 1)),"type":_vm.getTentValidationErrors &&
                _vm.getTentValidationErrors.light_on_time
                  ? 'is-danger'
                  : '',"message":_vm.getTentValidationErrors &&
                _vm.getTentValidationErrors.light_on_time
                  ? _vm.getTentValidationErrors.light_on_time
                  : ''}},[_c('b-input',{attrs:{"type":"time","icon":"","placeholder":"Light on time"},model:{value:(_vm.lightOnTime),callback:function ($$v) {_vm.lightOnTime=$$v},expression:"lightOnTime"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$tc('modules.tent.light_off_time', 1)),"type":_vm.getTentValidationErrors &&
                _vm.getTentValidationErrors.light_off_time
                  ? 'is-danger'
                  : '',"message":_vm.getTentValidationErrors &&
                _vm.getTentValidationErrors.light_off_time
                  ? _vm.getTentValidationErrors.light_off_time
                  : ''}},[_c('b-input',{attrs:{"type":"time","icon":"","placeholder":"Light off time"},model:{value:(_vm.lightOffTime),callback:function ($$v) {_vm.lightOffTime=$$v},expression:"lightOffTime"}})],1)],1)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }