import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Tent } from "@/config/Modules";

const TentModule = namespace(Tent.store);

@Component({})
export class TentStoreMixin extends Vue {
  /**
   * Actions
   */
  @TentModule.Action("create")
  protected createTentItemAction: any;

  @TentModule.Action("findOne")
  protected findOneTentItemAction: any;

  @TentModule.Action("update")
  protected updateTentItemAction: any;

  @TentModule.Action("findAll")
  protected findAllTentItemsAction: any;

  @TentModule.Action("resetSave")
  protected resetSaveTentAction: any;

  @TentModule.Action("resetList")
  protected resetListTentAction: any;

  @TentModule.Action("del")
  protected delTentItemAction: any;

  @TentModule.Action("findAllSubresource")
  protected findAllSubresourceTentItemsAction: any;

  @TentModule.Action("setDataList")
  protected setDataListTentAction: any;

  @TentModule.Action("upload")
  protected uploadTentAction: any;

  @TentModule.Action("findAttachedPlantsByTentId")
  protected findTentAttachedPlantsByTentIdAction: any;

  @TentModule.Action("detachPlantFromTent")
  protected detachPlantFromTentAction: any;

  /**
   * Getters
   */
  @TentModule.Getter("getError")
  protected getTentError: any;

  @TentModule.Getter("getIsLoading")
  protected getTentIsLoading: any;

  @TentModule.Getter("getIsSaving")
  protected getTentIsSaving: any;

  @TentModule.Getter("getDataItem")
  protected getTentItem: any;

  @TentModule.Getter("getSuccess")
  protected getTentSuccess: any;

  @TentModule.Getter("getDataList")
  protected getTentList: any;

  @TentModule.Getter("getTotal")
  protected getTentTotal: any;

  @TentModule.Getter("getDeletedSuccess")
  protected getTentDeletedSuccess: any;

  @TentModule.Getter("getValidationErrors")
  protected getTentValidationErrors: any;

  @TentModule.Getter("getAttachedPlants")
  protected getTentAttachedPlants: any;

  /**
   * Mutations
   */
  @TentModule.Mutation("IS_LOADING")
  protected setTentLoading: any;

  @TentModule.Mutation("SET_IS_SAVING")
  protected setTentIsSaving: any;

  @TentModule.Mutation("SET_ERROR")
  protected setTentError: any;

  @TentModule.Mutation("SET_SUCCESS")
  protected setTentSuccess: any;

  @TentModule.Mutation("SET_DATA_ITEM")
  protected setTentItem: any;

  @TentModule.Mutation("SET_DATA_LIST")
  protected setTentList: any;

  @TentModule.Mutation("SET_TOTAL")
  protected setTentTotal: any;

  @TentModule.Mutation("SET_DELETED_SUCCESS")
  protected setTentDeletedSuccess: any;

  @TentModule.Mutation("SET_VALIDATION_ERRORS")
  protected setTentValidationErrors: any;

  @TentModule.Mutation("SET_ATTACHED_PLANTS")
  protected setTentAttachedPlants: any;
}
