
import { Component, Prop, Vue } from "vue-property-decorator";
import i18n from "@/locales";

@Component({
  components: {},
})
export default class ConfirmButton extends Vue {
  @Prop({ required: false, default: false })
  public loading!: boolean;

  @Prop({ required: false, default: "is-success" })
  protected type!: string;

  @Prop({ required: false, default: "" })
  protected iconLeft!: string;

  @Prop({ required: false, default: "" })
  protected iconRight!: string;

  @Prop({ required: false, default: "" })
  protected size!: string;

  @Prop({ required: false, default: "" })
  protected resourceName!: string;

  @Prop({ required: false, default: false })
  protected disableConfirm!: boolean;

  @Prop({ required: false, default: true })
  protected dialogHasIcon!: boolean;

  @Prop({
    required: false,
    default: () => String(i18n.t("confirm_dialogs.confirm_dialog.title")),
  })
  protected dialogTitle!: string;

  @Prop({
    required: false,
    default: () => String(i18n.t("confirm_dialogs.confirm_dialog.message")),
  })
  protected dialogMessage!: string;

  @Prop({
    required: false,
    default: () =>
      String(i18n.t("confirm_dialogs.confirm_dialog.confirm_text")),
  })
  protected dialogConfirmText!: string;

  @Prop({
    required: false,
    default: () => String(i18n.t("confirm_dialogs.confirm_dialog.cancel_text")),
  })
  protected dialogCancelText!: string;

  protected onClick(): void {
    if (this.disableConfirm) {
      this.emitClick();
      return;
    }

    this.showConfirmDialog();
  }

  protected confirmed = false;

  protected showConfirmDialog(): void {
    this.$buefy.dialog.confirm({
      title: this.dialogTitle,
      message: this.dialogMessage,

      confirmText: this.dialogConfirmText,
      cancelText: this.dialogCancelText,
      type: this.type,
      hasIcon: this.dialogHasIcon,
      onConfirm: () => this.emitClick(),
      onCancel: () => {
        this.confirmed = false;
      },
    });
  }

  protected emitClick() {
    this.$emit("click", { resource_name: this.resourceName });
  }
}
