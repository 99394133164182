
import { Component, Prop, Watch } from "vue-property-decorator";
import FormMixin from "@/mixins/Crud/FormMixin";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import SaveButton from "@/components/buttons/table_actions/SaveButton.vue";
import { ITent } from "@/interfaces/models/ITent";
import { mixins } from "vue-class-component";
import { TentStoreMixin } from "@/mixins/Stores/TentStoreMixin";
import { STRAIN } from "@/config/FaIcon";
import tent from "@/router/Tent";

@Component({
  computed: {
    STRAIN_ICON() {
      return STRAIN;
    },
  },
  components: { SaveButton, DefaultForm },
})
export default class TentForm extends mixins(FormMixin, TentStoreMixin) {
  protected name = "TentForm.vue";

  @Prop({ required: false, default: "create" })
  public mode!: string;

  @Prop({ required: false, default: null })
  public data!: ITent;

  protected id: number | null = null;
  protected tent = "";
  protected heightCm = 0;
  protected widthCm = 0;
  protected lengthCm = 0;
  protected slotCount = 0;
  protected lightOnTime: string | null = null;
  protected lightOffTime: string | null = null;
  protected ownerId: number | null = null;

  @Watch("data", { immediate: true, deep: true })
  protected onDataChanged(data: ITent) {
    if (data) {
      this.setFormValues(data);
    }
  }

  public getFormValues(): ITent {
    return {
      id: this.id ?? undefined,
      tent: this.tent,
      height_cm: this.heightCm,
      width_cm: this.widthCm,
      length_cm: this.lengthCm,
      slot_count: this.slotCount,
      light_on_time: this.lightOnTime ?? null,
      light_off_time: this.lightOffTime ?? null,
      //owner_id: this.ownerId,
    };
  }

  protected setFormValues(data: ITent): void {
    if (data) {
      this.id = data.id ?? null;
      this.tent = data.tent;
      this.heightCm = data.height_cm;
      this.widthCm = data.width_cm;
      this.lengthCm = data.length_cm;
      this.slotCount = data.slot_count;
      this.lightOnTime = data.light_on_time ?? null;
      this.lightOffTime = data.light_off_time ?? null;
      this.ownerId = data.owner_id ?? null;
    }
  }

  protected onSubmit() {
    this.$emit("submit", this.getFormValues());
  }

  protected onAbort() {
    this.$emit("abort", this.getFormValues());
  }

  public init(): void {
    // Add inits
  }
}
