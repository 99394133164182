
import { Component, Vue } from "vue-property-decorator";
import TentForm from "@/views/GSSApp/Tent/TentForm.vue";
import { mixins } from "vue-class-component";
import UpdateMixin from "@/mixins/Crud/UpdateMixin";
import { TentStoreMixin } from "@/mixins/Stores/TentStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { Tent } from "@/config/Modules";
import TentUsage from "@/components/modules/tent/TentUsage.vue";

@Component({
  components: { TentUsage, TentForm },
})
export default class TentUpdate extends mixins(UpdateMixin, TentStoreMixin) {
  updateItemAction(options: ICrudOptions): any {
    return this.updateTentItemAction(options);
  }

  get descriptionField(): string {
    return Tent.description_field;
  }

  get resource(): string {
    return Tent.resource;
  }

  protected init(): void {
    this.findOneTentItemAction({
      resource: [this.resource, this.$route.params.id].join("/"),
    });
  }
}
