
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ITent } from "@/interfaces/models/ITent";
import { mixins } from "vue-class-component";
import { TentStoreMixin } from "@/mixins/Stores/TentStoreMixin";
import ConfirmButton from "@/components/buttons/ConfirmButton.vue";

@Component({
  components: { ConfirmButton },
})
export default class TentUsage extends mixins(TentStoreMixin) {
  @Prop({ required: true })
  protected tent!: ITent;

  @Watch("tent", { immediate: true, deep: true })
  protected onTentChanged(): void {
    this.fetchAttachedPlants();
  }

  protected fetchAttachedPlants(): void {
    if (this.tent?.id) {
      this.findTentAttachedPlantsByTentIdAction({
        id: this.tent.id,
      });
    }
  }

  protected removePlantFromTent(plantId: number): void {
    console.log("remove plant from tent", plantId);
    if (this.tent?.id && plantId) {
      this.detachPlantFromTentAction({
        id: this.tent.id,
        data: { id: plantId },
      });
    }
  }
}
