var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ul',_vm._l((_vm.getTentAttachedPlants),function(plant,plantIdx){return _c('li',{key:'tent-attached-plants-list--' + plantIdx,staticClass:"box py-4",staticStyle:{"min-height":"70px"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-quarter"},[_c('figure',{staticClass:"image is-64x64"},[_c('img',{directives:[{name:"auth-image",rawName:"v-auth-image",value:(plant.featured_media?.featured_url_thumb),expression:"plant.featured_media?.featured_url_thumb"}],staticClass:"my-auto",staticStyle:{"max-height":"100%","width":"auto"},attrs:{"alt":plant.plant}})])]),_c('div',{staticClass:"column"},[_vm._v(_vm._s(plant.plant))]),_c('div',{staticClass:"column is-one-quarter"},[_c('b-tooltip',{attrs:{"label":_vm.$t(
                'components.modules.tent.tent_usage.remove_plant_from_tent_button_tooltip'
              ),"position":"is-top"}},[_c('ConfirmButton',{attrs:{"type":"is-warning","icon-left":"fas fa-unlink","size":"is-small","dialog-title":String(
                  _vm.$t(
                    'components.modules.tent.tent_usage.remove_plant_from_tent_confirm_dialog.title'
                  )
                ),"dialog-message":String(
                  _vm.$t(
                    'components.modules.tent.tent_usage.remove_plant_from_tent_confirm_dialog.message'
                  )
                ),"dialog-confirm-text":String(
                  _vm.$t(
                    'components.modules.tent.tent_usage.remove_plant_from_tent_confirm_dialog.confirm_text'
                  )
                ),"dialog-cancel-text":String(
                  _vm.$t(
                    'components.modules.tent.tent_usage.remove_plant_from_tent_confirm_dialog.cancel_text'
                  )
                )},on:{"click":function($event){return _vm.removePlantFromTent(plant.id)}}})],1)],1)])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }