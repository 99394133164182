import { render, staticRenderFns } from "./ConfirmButton.vue?vue&type=template&id=4e5c1180&scoped=true"
import script from "./ConfirmButton.vue?vue&type=script&lang=ts"
export * from "./ConfirmButton.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e5c1180",
  null
  
)

export default component.exports